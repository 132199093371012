// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
Rails.start();

import Turbolinks from "turbolinks"
Turbolinks.start();

require("@rails/activestorage").start();

// And their styles (for UI plugins)
require('@uppy/core/dist/style.css');
require('@uppy/dashboard/dist/style.css');

import 'antd/dist/reset.css';
import '../vendor/antd/fixes.scss';
import '../stylesheets/application.scss';

import logger from 'loglevel';
import config from '../config/Config';

logger.setDefaultLevel(config.loglevel);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
